import React from "react";

export const Day = ({ day, onClick }) => {
  const { value, isCurrentDay, event } = day;
  const isPadding = value === "padding";
  const className = `day ${isPadding ? "padding" : ""} ${
    isCurrentDay ? "currentDay" : ""
  }`;

  return (
    <div onClick={onClick} className={className}>
      {!isPadding && value}

      {event && Number(event.title) > 0 && (
        <div className="event">{parseFloat(event.title).toFixed(2)}</div>
      )}
    </div>
  );
};

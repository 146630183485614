import React, { useState, useEffect, useContext } from "react";
import Tasks from "./Tasks";
import AddTask from "./AddTask";
import { UserContext } from "../UserContext";
import { toast } from "react-toastify";

export const NewEventModal = ({ onClose, date }) => {
  const { sntoken } = useContext(UserContext);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const getTasks = async () => {
      const tasksFromServer = await fetchTasks();
      if (tasksFromServer) {
        setTasks(tasksFromServer);
      }
    };

    getTasks();
  }, [date, sntoken]); // Added dependencies to useEffect

  const fetchTasks = async () => {
    const res = await fetch(`/expense/getexpenses?date=${date}`, {
      method: "GET",
      headers: { sntoken: sntoken },
    });
    const data = await res.json();

    return data.flag === "Y" ? data.data : [];
  };

  const addTask = async (task) => {
    const res = await fetch("/expense/addexpense", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        sntoken: sntoken,
      },
      body: JSON.stringify({ date, item: task.item, amount: task.amount }),
    });

    const data = await res.json();

    if (data.flag === "Y") {
      setTasks((prevTasks) => [...prevTasks, data.data]);
    }
  };

  const deleteTask = async (expenseid) => {
    const res = await fetch(
      `/expense/deleteexpense?date=${date}&expenseid=${expenseid}`,
      {
        method: "DELETE",
        headers: { sntoken: sntoken },
      }
    );

    const data = await res.json();

    if (data.flag === "Y") {
      setTasks(tasks.filter((task) => task.expenseid !== expenseid));
    } else {
      toast.error("Error Deleting This Task");
    }
  };

  const total = tasks.reduce(
    (acc, task) => acc + parseFloat(task.amount || 0),
    0
  );
  const roundedTotal = Math.round(total * 100) / 100;

  return (
    <>
      <div id="newEventModal">
        <center>
          <h3>
            {new Date(date).toLocaleDateString("en-us", {
              weekday: "long",
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
            : <b id="totalamount">{`${roundedTotal}`}</b>
          </h3>
          <AddTask onAdd={addTask} />
          <Tasks tasks={tasks} onDelete={deleteTask} />
          <br></br>
          <button onClick={onClose} id="cancelButton">
            Cancel
          </button>
        </center>
      </div>
      <div id="modalBackDrop"></div>
    </>
  );
};

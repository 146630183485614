import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import PWAPrompt from 'react-ios-pwa-prompt'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App /><PWAPrompt copyTitle={"Install progressive web app"} copyBody={"Add it to your home screen to use it in fullscreen"}/>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePen } from "@fortawesome/free-solid-svg-icons";

function Register() {
  const [user, setUser] = useState({ name: "", email: "", password: "" });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const { name, email, password } = user;
    if (!name || !email || !password) {
      toast.error("Please fill in all fields");
      return;
    }

    const res = await fetch("/users/saveuser", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(user),
    });

    const data = await res.json();

    if (data.status === "N") {
      toast.error(JSON.stringify(data.message));
    } else {
      setUser({ name: "", email: "", password: "" });
      navigate("/");
      toast.success("User saved successfully. Please login");
    }
  };

  return (
    <div id="login">
      <center>
        <h3>
          Expense Tracker <FontAwesomeIcon icon={faFilePen} />
        </h3>
      </center>
      <center>
        <form onSubmit={onSubmit}>
          <div className="form-control">
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={user.name}
              onChange={handleChange}
            />
          </div>
          <div className="form-control">
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={user.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-control">
            <input
              type="password"
              placeholder="Password"
              name="password"
              value={user.password}
              onChange={handleChange}
            />
          </div>
          <div className="form-control">
            <input
              style={{ fontSize: "15px" }}
              type="submit"
              value="Register"
              className="btn btn-block"
            />
          </div>
        </form>
      </center>
      <br></br>
      <center>
        <Link to="/">
          <button style={{ color: "green" }}>Already registered?</button>
        </Link>
      </center>
    </div>
  );
}

export default Register;

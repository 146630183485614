import { FaTimes } from "react-icons/fa";

const Task = ({ task, onDelete }) => {
  const handleDelete = () => {
    onDelete(task.expenseid);
  };

  return (
    <div className="task reminder">
      <FaTimes
        style={{ color: "red", cursor: "pointer", float: "right" }}
        onClick={handleDelete}
      />
      <h3>{task.item}</h3>
      <p id="monthTotal">
        <b>{`💸 ${parseFloat(task.amount).toFixed(2)}`}</b>
      </p>
    </div>
  );
};

export default Task;

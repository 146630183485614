import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Calendar from "./Calendar";
import Login from "./Login";
import Register from "./Register";
import { UserContext } from "./UserContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [sntoken, setSntoken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    const handleStorageChange = () => {
      setSntoken(localStorage.getItem("token"));
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  const renderBasedOnToken = (Component) =>
    sntoken ? <Component /> : <Login />;

  return (
    <div className="App">
      <Router>
        <UserContext.Provider value={{ sntoken, setSntoken }}>
          <Routes>
            <Route exact path="/" element={renderBasedOnToken(Calendar)} />
            <Route
              path="/register"
              element={sntoken ? <Calendar /> : <Register />}
            />
          </Routes>
          <ToastContainer />
        </UserContext.Provider>
      </Router>
    </div>
  );
}

export default App;

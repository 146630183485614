import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePen } from "@fortawesome/free-solid-svg-icons";

function Login() {
  const { setSntoken } = useContext(UserContext);
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({ email: "", password: "" });

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = credentials;

    if (!email || !password) {
      toast.error("Please add both email and password");
      return;
    }

    const res = await fetch(`/users/login`, {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(credentials),
    });

    const data = await res.json();

    if (data.status === "N") {
      toast.error(JSON.stringify(data.message));
    } else {
      setSntoken(data.sntoken);
      localStorage.setItem("token", data.sntoken);
      setCredentials({ email: "", password: "" });
      navigate("/");
    }
  };

  return (
    <div id="login">
      <center>
        <h3>
          Expense Tracker <FontAwesomeIcon icon={faFilePen} />
        </h3>
      </center>
      <center>
        <form onSubmit={onSubmit}>
          <div className="form-control">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={credentials.email}
              onChange={handleChange}
              maxLength="100"
            />
          </div>
          <div className="form-control">
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={credentials.password}
              onChange={handleChange}
              maxLength="100"
            />
          </div>
          <div className="form-control">
            <input
              style={{ fontSize: "15px" }}
              type="submit"
              value="Login"
              className="btn btn-block"
            />
          </div>
        </form>
      </center>
      <br />
      <center>
        <Link to="/register">
          <button style={{ color: "red" }}>New user?</button>
        </Link>
      </center>
    </div>
  );
}

export default Login;

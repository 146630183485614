import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { Day } from "./components/Day";
import { NewEventModal } from "./components/NewEventModal";
import { useDate } from "./hooks/useDate";
import { UserContext } from "./UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPowerOff,
  faArrowLeft,
  faArrowRight,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";

function Calendar() {
  const { sntoken, setSntoken } = useContext(UserContext);
  const [nav, setNav] = useState(0);
  const [clicked, setClicked] = useState();
  const [events, setEvents] = useState([]);
  const [monthtotal, setMonthtotal] = useState([]);

  const fetchTotal = useCallback(
    async (value) => {
      try {
        const res = await fetch(
          `/expense/gettotal?date=${value.datevalue}&flag=${value.flag}`,
          {
            method: "GET",
            headers: { sntoken: sntoken },
          }
        );
        const data = await res.json();

        if (data.flag === "Y") {
          setEvents(data.data);
          setMonthtotal(data.monthtotal);
        } else {
          console.error(data.data);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    },
    [sntoken]
  );

  useEffect(() => {
    fetchTotal({
      datevalue: new Date().toISOString().split("T")[0],
      flag: "INITIAL",
    });
  }, [fetchTotal]);

  const { days, dateDisplay } = useDate(events, nav);

  const roundedMonthtotal = useMemo(
    () => Math.round(parseFloat(monthtotal) * 100) / 100,
    [monthtotal]
  );

  const handleDayClick = (d) => {
    if (d.value !== "padding") {
      setClicked(d.date);
    }
  };

  const handleNavChange = (change) => {
    setNav(nav + change);
    fetchTotal({
      datevalue: dateDisplay,
      flag: change === -1 ? "BACK" : "NEXT",
    });
  };

  const handleLogout = () => {
    setSntoken(null);
    localStorage.removeItem("token");
  };

  return (
    <>
      <div id="container">
        <div className="header">
          <div>
            <FontAwesomeIcon icon={faCalendarDays} />
            &nbsp;
            {dateDisplay}: <b id="monthTotal">{`${roundedMonthtotal}`}</b>
          </div>
        </div>

        <div id="weekdays">
          {[
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ].map((day) => (
            <div key={day}>{day}</div>
          ))}
        </div>

        <div id="calendar">
          {days.map((d, index) => (
            <Day key={index} day={d} onClick={() => handleDayClick(d)} />
          ))}
        </div>

        <center className="footer">
          <button
            onClick={() => handleNavChange(-1)}
            style={{ height: "max-content" }}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </button>
          <h3>
            <button
              style={{ color: "white", background: "#d36c6c" }}
              onClick={handleLogout}
            >
              Logout <FontAwesomeIcon icon={faPowerOff} />
            </button>
          </h3>
          <button
            style={{ height: "max-content" }}
            onClick={() => handleNavChange(1)}
          >
            Next <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </center>
      </div>

      {clicked && (
        <NewEventModal
          onClose={() => {
            setClicked(null);
            fetchTotal({ datevalue: new Date(clicked), flag: "CANCEL" });
          }}
          date={clicked}
        />
      )}
    </>
  );
}

export default Calendar;

import React, { useState } from "react";
import { toast } from "react-toastify";

const AddTask = ({ onAdd }) => {
  const [item, setItem] = useState("");
  const [amount, setAmount] = useState("");

  const isNumeric = (num) => /^-?\d+(\.\d+)?$/.test(num);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!item) {
      toast.error("Please add an item");
      return;
    }

    if (!amount) {
      toast.error("Please add amount");
      return;
    }

    if (!isNumeric(amount)) {
      toast.error("Amount should be a number");
      return;
    }

    onAdd({ item, amount: parseFloat(amount) });
    setItem("");
    setAmount("");
  };

  return (
    <center>
      <form onSubmit={onSubmit}>
        <div className="form-control">
          <input
            type="text"
            placeholder="Add Item"
            value={item}
            onChange={(e) => setItem(e.target.value)}
            maxLength="100"
          />
        </div>
        <div className="form-control">
          <input
            type="text"
            placeholder="Add Amount"
            value={amount}
            maxLength="10"
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div className="form-control">
          <input
            style={{ fontSize: "15px" }}
            type="submit"
            value="Add item"
            className="btn btn-block"
          />
        </div>
      </form>
    </center>
  );
};

export default AddTask;
